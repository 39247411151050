import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  Hidden,
  Button,
  Dialog,
  IconButton,
  Grid,
  Icon,
  TextField,
  Slide,
} from "@material-ui/core";
import ReactDataGrid from "react-data-grid";
import PagesSubMenu from "../components/more/PagesSubMenu";
import Users from "../components/more/Users";
import { getChurches } from "../services/api";
import { authHeader } from "../components/helpers/AuthHeader";

import { CSVLink } from "react-csv";
import Moment from "react-moment";
import HeaderMore from "../components/more/HeaderMore";
import FilterChecklist from "../components/FilterChecklist/FilterChecklist";
import OrganizationTable from "../components/OrganizationTable";
import { countries } from "country-data";
import {
  togglePlanFilterIcon,
  toggleStatusFilterIcon,
} from "../store/actions/organizationActions";
import "../styles/components/more/users.scss";
import "../styles/components/more/billing.scss";

Moment.globalFormat = "MMMM DD, YYYY"; // hh:mm A";
Moment.globalLocal = true;

const API_URL = process.env.REACT_APP_API_URL;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TableAction = ({ toggleUserModal, ...rest }) => {
  return (
    <Button className="single-card confirm" onClick={toggleUserModal}>
      View
    </Button>
  );
};

const headers = [
  { label: "ID", key: "id" },
  { label: "Org Name", key: "orgName" },
  { label: "Status", key: "status" },
  { label: "Plan", key: "plan" },
  { label: "Active Integrations", key: "integrations" },
  { label: "Signup date", key: "signupDate" },
];

const EmptyRowsView = () => {
  const message = "No data to show";
  return (
    <div
      style={{ textAlign: "center", backgroundColor: "#ddd", padding: "100px" }}
    >
      <h3>{message}</h3>
    </div>
  );
};

class Organizations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserModal: false,
      selectedOrg: {},
      rows: [],
      churches: [],
      plans: [],
      statuses: [],
      allChurches: [],
      updatedStatuses: [],
      updatedPlans: [],
      allOrganizations: [],
      loadMoreData: false,
      hasSearchData: false,
      search: {
        church: "",
        username: "",
        name: "",
        email: "",
        number: "",
      },
    };
  }

  componentDidMount() {
    this.getAllChurches();
  }

  handleSearch = () => {
    this.searchChurches();
  };

  resetFilter = (plans, statuses) => {
    plans.forEach((plan) => (plan.active = false));
    statuses.forEach((status) => (status.active = false));
  };

  handleReset = () => {
    const { plans, statuses } = this.state;
    this.resetFilter(plans, statuses);
    this.props.togglePlanFilterIcon(false);
    this.props.toggleStatusFilterIcon(false);
    this.setState({
      rows: this.state.churches,
      statuses,
      plans,
      hasSearchData: false,
      search: {
        church: "",
        username: "",
        name: "",
        email: "",
        number: "",
      },
    });
  };

  handleChange = (event) => {
    var search = this.state.search;
    search[event.currentTarget.id] = event.currentTarget.value;
    this.setState({ search });
  };

  toggleUserModal = () => {
    this.setState({ showUserModal: !this.state.showUserModal });
  };

  getCellActions = (rowIdx, row, column) => {
    if (column?.key === "action") {
      this.setState({ selectedOrg: row });
    }
  };

  getAllChurches = async () => {
    this.setState({ loading: true });
    const data = await getChurches();
    const rows = this.setRows(data?.churches);
    const changeStatusesArrayIntoObj = this.addNewKeyInObj(data.statuses);
    const changePlansArrayIntoObj = this.addNewKeyInObj(data.plans);
    const sliceData = rows.slice(0, 15);
    this.setState({
      rows: sliceData,
      churches: rows,
      loading: false,
      plans: changePlansArrayIntoObj,
      statuses: changeStatusesArrayIntoObj,
      allChurches: data?.churches,
      allOrganizations: rows,
    });
  };

  setRows = (churches) => {
    const rows = [];
    churches?.map((row) =>
      rows.push({
        id: row.id,
        created_at: row.created_at,
        orgName:
          row.name +
          (row.intercom_tag && row.intercom_tag.includes("Confirmed")
            ? " (No Church)"
            : ""),
        status: row.intercom_tag,
        plan: row.plan ? row.plan : "free",
        country:
          row.country_iso && countries[row.country_iso]
            ? countries[row.country_iso].name
            : "",
        integrations: row.integrations ? row.integrations : "",
        signupDate: row.created_at ? <Moment>{row.created_at}</Moment> : "",
        action: <TableAction toggleUserModal={this.toggleUserModal} />,
      })
    );
    return rows;
  };

  addNewKeyInObj = (dataInArray) => {
    if (!dataInArray.length) return [];
    const changeDataStructure = dataInArray.map((item) => {
      return {
        active: false,
        key: item,
      };
    });
    return changeDataStructure;
  };

  searchChurches = async () => {
    this.setState({ loading: true });
    const { plans, statuses } = this.state;
    this.resetFilter(plans, statuses);
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify(this.state.search),
    };

    const rows = [];
    return fetch(`${API_URL}search-churches`, requestOptions)
      .then((res) => {
        if (!res.ok) {
          if (res.status !== 401) return;
        }
        return res.json();
      })
      .then((data) => {
        if (data) {
          data.map((row) =>
            rows.push({
              id: row.id,
              created_at: row.created_at,
              orgName:
                row.name +
                (row.intercom_tag && row.intercom_tag.includes("Confirmed")
                  ? " (No Church)"
                  : ""),
              status: row.intercom_tag,
              plan: row.plan ? row.plan : "free",
              integrations: row.integrations ? row.integrations : "",
              signupDate: row.created_at ? (
                <Moment>{row.created_at}</Moment>
              ) : (
                ""
              ),
              action: <TableAction toggleUserModal={this.toggleUserModal} />,
            })
          );
          this.props.togglePlanFilterIcon(false);
          this.props.toggleStatusFilterIcon(false);
          this.setState({
            rows,
            loading: false,
            plans,
            statuses,
            hasSearchData: true,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch(console.log);
  };

  prepareForCSV(rows) {
    var logArray = [];
    rows.forEach((row) => {
      logArray.push({
        id: row.id,
        orgName: row.orgName,
        status: row.status,
        plan: row.plan,
        integrations: row.integrations,
        signupDate: row.created_at,
      });
    });

    return logArray;
  }

  checkForActiveFilter = (filters) => {
    return filters.some((filter) => filter.active);
  };

  filterDataByStatus = (newData) => {
    const { allChurches, updatedPlans } = this.state;
    const { toggleStatusFilterIcon } = this.props;
    const filterData = this.filterDataByStatusAndPlans(newData, "status");
    const isActiveFilter = this.checkForActiveFilter([
      ...updatedPlans,
      ...newData,
    ]);
    const filteredData = isActiveFilter
      ? this.setRows(filterData)
      : this.setRows(allChurches);
    const hasStatusActive = newData.some((status) => status.active);
    toggleStatusFilterIcon(hasStatusActive);
    this.setState({ rows: filteredData, updatedStatuses: newData });
  };

  filterDataByPlans = (newData) => {
    const { allChurches, updatedStatuses } = this.state;
    const { togglePlanFilterIcon } = this.props;
    const filterData = this.filterDataByStatusAndPlans(newData, "plans");
    const isActiveFilter = this.checkForActiveFilter([
      ...updatedStatuses,
      ...newData,
    ]);
    const filteredData = isActiveFilter
      ? this.setRows(filterData)
      : this.setRows(allChurches);
    const hasPlanActive = newData.some((plan) => plan.active);
    togglePlanFilterIcon(hasPlanActive);
    this.setState({ rows: filteredData, updatedPlans: newData });
  };

  filterDataByStatusAndPlans = (newData, key) => {
    const { allChurches, updatedPlans, updatedStatuses } = this.state;
    if (key === "status") {
      const filterActiveStatuses = newData
        .filter((item) => item.active)
        .map((el) => el.key);
      const filterActivePlans = updatedPlans
        .filter((item) => item.active)
        .map((el) => el.key);
      if (filterActivePlans.length && filterActiveStatuses.length) {
        const filterData = allChurches.filter(
          (church) =>
            (filterActivePlans.includes(church.plan) ||
              (filterActivePlans.includes("free") && !church.plan)) &&
            filterActiveStatuses.includes(church.intercom_tag)
        );
        return filterData;
      } else if (filterActivePlans.length && !filterActiveStatuses.length) {
        const filterData = allChurches.filter(
          (church) =>
            filterActivePlans.includes(church.plan) ||
            (filterActivePlans.includes("free") && !church.plan)
        );
        return filterData;
      } else {
        const filterData = allChurches.filter((church) =>
          filterActiveStatuses.includes(church.intercom_tag)
        );
        return filterData;
      }
    } else if (key === "plans") {
      const filterActivePlans = newData
        .filter((item) => item.active)
        .map((el) => el.key);
      const filterActiveStatuses = updatedStatuses
        .filter((item) => item.active)
        .map((el) => el.key);
      if (filterActiveStatuses.length && filterActivePlans.length) {
        const filterData = allChurches.filter(
          (church) =>
            filterActiveStatuses.includes(church.intercom_tag) &&
            (filterActivePlans.includes(church.plan) ||
              (filterActivePlans.includes("free") && !church.plan))
        );
        return filterData;
      } else if (!filterActivePlans.length && filterActiveStatuses.length) {
        const filterData = allChurches.filter((church) =>
          filterActiveStatuses.includes(church.intercom_tag)
        );
        return filterData;
      } else {
        const filterData = allChurches.filter(
          (church) =>
            filterActivePlans.includes(church.plan) ||
            (filterActivePlans.includes("free") && !church.plan)
        );
        return filterData;
      }
    }
  };

  populateData = (data) => {
    this.setState({ rows: [...this.state.rows, ...data] });
  };

  render() {
    const {
      showUserModal,
      selectedOrg,
      rows,
      search,
      statuses,
      plans,
      allOrganizations,
      hasSearchData,
    } = this.state;
    const hasActivePlans = plans.some((plan) => plan.active);
    const hasActiveStatus = statuses.some((status) => status.active);
    const isFiltersActive = hasActivePlans || hasActiveStatus;
    const columns = [
      { key: "orgName", name: "Org Name" },
      {
        key: "status",
        name: (
          <Fragment>
            Status{" "}
            <FilterChecklist
              checkList={statuses}
              filteration={this.filterDataByStatus}
              hasSearchData={hasSearchData}
            />
          </Fragment>
        ),
        width: 150,
      },
      {
        key: "plan",
        name: (
          <Fragment>
            <span>Plans</span>
            <FilterChecklist
              checkList={plans}
              filteration={this.filterDataByPlans}
            />
          </Fragment>
        ),
        width: 150,
      },
      { key: "integrations", name: "Active Integrations", width: 180 },
      { key: "country", name: "Country" },
      { key: "signupDate", name: "Signup date" },
      { key: "action", name: "Action", width: 180 },
    ];
    return (
      <Fragment>
        <div className="users-page">
          <Hidden smDown>
            <PagesSubMenu />
          </Hidden>
          <Hidden mdUp>
            <HeaderMore backView="/dashboard" title="Organizations" />
          </Hidden>
          <div
            className="top-section desktop-filter "
            style={{
              marginLeft: window.innerWidth < 1200 ? 0 : 300,
              marginTop: 100,
            }}
          >
            <div className="filter" style={{ width: "90%" }}>
              <Grid container spacing={2} className="container">
                <Grid item style={{ width: "15%" }}>
                  <TextField
                    id="church"
                    type="text"
                    value={search.church}
                    onChange={this.handleChange}
                    fullWidth
                    variant="filled"
                    placeholder="Company"
                    inputProps={{
                      className: "organization-filter",
                    }}
                  />
                </Grid>
                <Grid item style={{ width: "14%" }}>
                  <TextField
                    id="username"
                    type="text"
                    value={search.username}
                    onChange={this.handleChange}
                    fullWidth
                    variant="filled"
                    placeholder="Username"
                    inputProps={{
                      className: "organization-filter",
                    }}
                  />
                </Grid>
                <Grid item style={{ width: "14%" }}>
                  <TextField
                    id="name"
                    type="text"
                    value={search.name}
                    onChange={this.handleChange}
                    fullWidth
                    variant="filled"
                    placeholder="Full name"
                    inputProps={{
                      className: "organization-filter",
                    }}
                  />
                </Grid>
                <Grid item style={{ width: "14%" }}>
                  <TextField
                    id="email"
                    fullWidth
                    type="email"
                    value={search.email}
                    onChange={this.handleChange}
                    variant="filled"
                    placeholder="Email"
                    inputProps={{
                      className: "organization-filter",
                    }}
                  />
                </Grid>
                <Grid item style={{ width: "14%" }}>
                  <TextField
                    id="number"
                    type="number"
                    value={search.number}
                    onChange={this.handleChange}
                    fullWidth
                    variant="filled"
                    placeholder="Phone number"
                    inputProps={{
                      className: "organization-filter",
                    }}
                  />
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ color: "white" }}
                    onClick={this.handleSearch}
                  >
                    <Icon>search</Icon>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    className="outlined"
                    onClick={this.handleReset}
                  >
                    Reset
                  </Button>
                </Grid>
                <CSVLink
                  filename={"users.csv"}
                  data={this.prepareForCSV(rows)}
                  headers={headers}
                >
                  <IconButton className="icon-button">
                    <Icon style={{ color: "#7BC807" }}>get_app</Icon>
                  </IconButton>
                </CSVLink>
              </Grid>
            </div>
          </div>
        </div>
        <div
          className="inner-content-wrapper"
          style={{ width: "100%", position: "absolute", bottom: 0 }}
        >
          {Boolean(rows.length) ? (
            <OrganizationTable
              rows={rows}
              columns={columns}
              getCellActions={this.getCellActions}
              allOrganizations={allOrganizations}
              populateData={this.populateData}
              isFiltersActive={isFiltersActive || hasSearchData}
            />
          ) : (
            <div className="react-grid-users-table">
              <ReactDataGrid
                idProperty="id"
                columns={columns}
                rowGetter={(i) => rows[i]}
                rowsCount={rows.length}
                rowHeight={50}
                emptyRowsView={EmptyRowsView}
              />
            </div>
          )}
        </div>
        {showUserModal && (
          <Dialog
            fullScreen
            open={showUserModal}
            onClose={this.toggleUserModal}
            TransitionComponent={Transition}
            classes={{
              root: "logs-list-page",
              paper: "paper",
            }}
            BackdropProps={{
              classes: {
                root: "backdrop",
              },
            }}
          >
            <IconButton className="close-icon" onClick={this.toggleUserModal}>
              <Icon>close</Icon>
            </IconButton>
            <Users
              selectedOrg={selectedOrg}
              getChurches={this.getAllChurches}
            />
          </Dialog>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    showPlanFilterIcon: store.organization.showPlanFilterIcon,
  };
};

const mapDispatchToProps = (dispatch) => ({
  togglePlanFilterIcon: (val) => dispatch(togglePlanFilterIcon(val)),
  toggleStatusFilterIcon: (val) => dispatch(toggleStatusFilterIcon(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Organizations);
